

  .box {
    width: 100px;
    height: 100px;
    cursor: pointer;

  }






/* Style the scrollbar */
.box::-webkit-scrollbar {
  width: 5px;  
 /* width: 0;  */
  border-radius: 4px; 
  background-color: #464545; 
}



/* Style the scrollbar thumb */
.box::-webkit-scrollbar-thumb {
  background-color: #ffffff; /* Set the color of the scrollbar thumb */
  border-radius: 4px; /* Set the desired border radius for the scrollbar thumb */
}

/* Style the scrollbar thumb on hover */
.box::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff; /* Set the color of the scrollbar thumb on hover */
}






.childBox::-webkit-scrollbar {
  width: 100%; /* Set the width of the scrollbar */
  height:5px;
  border-radius: 4px; /* Set the desired border radius for the scrollbar */
  background-color: #f8f9fa; /* Set the background color of the scrollbar track */

}



/* Style the scrollbar thumb */
.childBox::-webkit-scrollbar-thumb {
  background-color: #55595c; /* Set the color of the scrollbar thumb */
  border-radius: 4px; /* Set the desired border radius for the scrollbar thumb */
}

/* Style the scrollbar thumb on hover */
.childBox::-webkit-scrollbar-thumb:hover {
  background-color: #55595c; /* Set the color of the scrollbar thumb on hover */
}









#boxToMove {
  position: absolute;
  top: 0; /* Adjust this initial position as per your requirements */
  left: 0; /* Adjust this initial position as per your requirements */
  display: none; /* Hide the box initially */
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px;
}






